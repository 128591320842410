var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "content"
  }, [_c("div", {
    staticClass: "newsDetail"
  }, [_c("div", {
    staticClass: "newsDetail-hd"
  }, [_c("div", {
    staticClass: "news-title"
  }, [_vm._v(_vm._s(_vm.data.storyTitle))])]), _c("div", {
    staticClass: "newsDetail-bd",
    domProps: {
      innerHTML: _vm._s(_vm.data.storyContext)
    }
  }), _c("div", {
    staticClass: "newsDetail-ft"
  }, [_vm.language == 1 ? _c("a", {
    staticClass: "newsDetail-back",
    on: {
      click: _vm.reback
    }
  }, [_vm._v("返回上级")]) : _vm._e(), _vm.language == 2 ? _c("a", {
    staticClass: "newsDetail-back",
    on: {
      click: _vm.reback
    }
  }, [_vm._v("Return")]) : _vm._e(), _vm.language == 3 ? _c("a", {
    staticClass: "newsDetail-back",
    on: {
      click: _vm.reback
    }
  }, [_vm._v("返回上級")]) : _vm._e()])])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };