import { mapState } from 'vuex';
export default {
  name: "storyDetail",
  data() {
    return {
      data: {}
    };
  },
  computed: {
    ...mapState({
      device: state => state.device,
      language: state => state.language
    })
  },
  watch: {},
  created() {
    this.getData();
  },
  methods: {
    reback() {
      this.$router.go(-1);
    },
    getData() {
      this.$http.post(this.$api.storyDetail, {
        id: this.$route.query.id
      }).then(res => {
        if (res.code == 0) {
          this.data = res.data;
        }
      });
    }
  }
};